import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';


const Page_catering_service_index = () => (
  <Layout>
    <SEO 
      title="Easiest Way to Start and Run a Food and Catering Business"
      description="HomeCatered Apps for Starting and Running Catering Service Business."
      keywords="Home Based Business, Side Business, Catering Business, Home Based Catering Business, Social Catering, Side Hustle, Additional Income"
    /> 



    <section className="pt-0">
      <div className="grid container mx-auto px-2 py-2 sm:px-4 sm:py-4 flex" >
        {/* <h2 className="text-2xl lg:text-4xl font-semibold text-primary text-center">Cooking or Eating?</h2> */}
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-5">
          {/* From https://tailwindcomponents.com/component/horizontal-card */}
          
          <div className="w-full mr-0 lg:mr-5 mb-5 lg:mb-0">
            <p className="mt-8 font-semibold text-xl lg:text-2xl text-primary text-center">HomeCatered is now LocalChefs</p>
            <p className="mt-8 sm:font-semibold text-lg md:text-xl text-center">We will redirect you to LocalChefs.us in 10 seconds . . .</p>
            <p className="mt-8 md:font-semibold text-lg md:text-xl text-center">OR</p>
            <p className="mt-8 md:font-semibold text-lg md:text-xl text-center">You can click on the link below to redirect immediately.</p>
            <a href="https://localchefs.us">
              <div className="mt-8 font-semibold text-xl lg:text-2xl text-center text-primary">
              LocalChefs.us
              </div>
            </a>
            <div className="text-center">
              <div>LocalChefs app allows cooking enthusiasts to offer their unique dishes to consumers.</div>
              <div>The app lets foodies discover such delicious home food in the neighborhood.</div>
            </div>
          </div>

        </div>
      </div>
    </section>

    {/* Workaround for footer encroaching on the bottom part of the SplitSection above. WHY? */}
    <section className="pt-5">
      <div className="grid container mx-auto px-2 py-2 sm:px-4 sm:py-4 flex" >
        &nbsp; 
      </div>
    </section>

    
  </Layout>
);
export default Page_catering_service_index;
